
export enum MENUTYPE {

  BUTTON,
  DROPDOWN,
  INDROPBUTTON

}

export interface MenuInterface {

  type : MENUTYPE,
  text : string
  link? : string,
  links? : Array<MenuInterface>

}

var links : Array<MenuInterface> = [

    {
      type : MENUTYPE.BUTTON,
      link : "/",
      text : 'Головна',
    },

    {

      type : MENUTYPE.DROPDOWN,
      text : 'Каталог',
      links : [

        {
          type : MENUTYPE.INDROPBUTTON,
          link : "/entry-doors",
          text : 'Вхідні двері',
        },
        {
          type : MENUTYPE.INDROPBUTTON,
          link : "/interior-doors",
          text : 'Міжкімнатні двері',
        },
        {
          type : MENUTYPE.INDROPBUTTON,
          link : "/glass-partitions",
          text : 'Скляні перегородки',
        },
        {
          type : MENUTYPE.INDROPBUTTON,
          link : "/flooring",
          text : 'Підлога',
        },
        {
          type : MENUTYPE.INDROPBUTTON,
          link : "/hardware",
          text : 'Фурнітура',
        },

      ]

    },
    {
      type : MENUTYPE.BUTTON,
      link : "/about",
      text : 'Галерея',
    },
    {
      type : MENUTYPE.BUTTON,
      link : "/contact",
      text : 'Контакти',
    },
  ]

export {links}