import { useState } from 'react';
import MenuList from '../../MenuList';
import { Text, TextStyles } from '../../../text';
import { Image, ImageStyles } from '../../../image';
import { MenuInterface } from '../../../../feature/menu/MenuConfig';

export interface DropDownProps {
  key?: string;
  maintext: string;
  dropdown: Array<MenuInterface>;
}

function Dropdown(props: DropDownProps) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <div onClick={toggleMenu} className='w-full md:w-max md:left-auto'>
      <div className="flex place-content-center items-center">
        <Text text={props.maintext} textStyle={TextStyles.dropDownButton}></Text>
        <Image path={'/gfx/arrow.png'} imageStyle={`${ImageStyles.dropdownIco} ${isOpen ? 'animate-swipe scale-y-[1] md:transform-none' : ''}`}></Image>
      </div>
      {isOpen && (
        <div>
          <ul className={"bg-[#515151] space-y-6 py-4 text-center shadow-md w-full left-0 translate-y-2 md:absolute md:translate-y-2 md:translate-x-[-4rem] md:w-max md:left-auto"} onMouseLeave={closeMenu}>
            {<MenuList array={props.dropdown}></MenuList>}
          </ul>
        </div>
      )}
    </div>
  );
}

export default Dropdown;
