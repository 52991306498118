import React from "react";
import { Text,TextStyles } from "../../components/text";
import { ContactBlock } from "../../components/contactblock";

function Footer() : React.JSX.Element {
  return (
    <footer className="md:flex w-full h-full text-white">
      <div className="relative w-full h-96 md:h-auto">
        <iframe 
          title="Google Maps"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1089.8455610655978!2d23.50770410126224!3d49.2859467639291!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473a497bf1ac6c57%3A0x789307ba73ed5ad8!2z0YHRgtGD0LTRltGPINC00LLQtdGA0LXQuSAiVElORU8i!5e0!3m2!1suk!2spl!4v1691267228929!5m2!1suk!2spl" 
          width="100%" 
          height="100%" 
          loading="lazy" 
          referrerPolicy="no-referrer-when-downgrade">
        </iframe>
      </div>
      <div className='bg-[#515151] w-full md:w-max md:px-12'>
        <div className='pt-5 md:pt-0'><Text text='Контактні дані' textStyle={TextStyles.contactTitle}></Text></div>
        <ContactBlock></ContactBlock>
      </div>
      

    </footer>
  );
}

export default Footer;