import { Image,ImageStyles } from "../../components/image";
import { ImagePopup } from "../../components/imagePopup";

export interface CategoryImagesProps {

    key? : string
    titleImage : string
    pathImages : Array<string>

}

function CategoryImages(props : CategoryImagesProps){

    const images = []

    for (let i = 0; i < props.pathImages.length;i++){
        const listImage = []
        const image = props.pathImages[i]

        for (const imageData of props.pathImages){
            listImage.push({key: imageData, path: imageData, imageStyle: ImageStyles.categoryIco})
        }

        images.push(
            //<Image path={image} imageStyle={ImageStyles.categoryIco}></Image>
            <ImagePopup key={props.pathImages.indexOf(image)} specialStyle="" images={listImage} initialIndex={i} borderStyle="relative w-full h-full md:aspect-[3/2]"></ImagePopup>
        )
        
    }


    return  (
        <div className="w-full h-min">
            <div>
                <div className="flex bg-[#fff] w-full">
                    <div className="flex bg-[#484848] w-full h-8 justify-center items-center my-12 justify-center items-center">
                        <div className="flex bg-white w-max h-full justify-center items-center">
                            <Image path={props.titleImage} imageStyle={ImageStyles.categoryPartnerIco}></Image>
                        </div>
                    </div>
                </div>
                <div className="grid md:grid-cols-4 gap-x-4 gap-y-4 justify-center items-center px-12">
                    {images}
                </div>

            </div>
        </div>
    );

}

export {CategoryImages}