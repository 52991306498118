export enum PageType {
    NORMAL = 0,
    TEMPLATE = 1
}

export interface PagesInterface {

  path: string,
  pageType : PageType

}

const pages: Array<PagesInterface> = [

    {
      path : "/",
      pageType: PageType.NORMAL,
    },
    {
      path : "/entry-doors",
      pageType: PageType.TEMPLATE
    },
    {
      path : "/interior-doors",
      pageType: PageType.TEMPLATE
    },
    {
      path : "/glass-partitions",
      pageType: PageType.TEMPLATE
    },
    {
      path : "/flooring",
      pageType: PageType.TEMPLATE
    },
    {
      path : "/hardware",
      pageType: PageType.TEMPLATE
    },
    {
      path : "/about",
      pageType: PageType.NORMAL
    },
    {
      path : "/contact",
      pageType: PageType.NORMAL
    }
    
]

export {pages}
  