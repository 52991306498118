import React from 'react';
import { Darkness } from '../darkness';
import { Image,ImageStyles } from '../image';
import { Text,TextStyles } from '../text'
import { useNavigate } from 'react-router-dom';
import { GalleryInterface } from '../../feature/gallery/galleryData';
import { useState } from 'react';

export interface CardProps {

    cardData : GalleryInterface

}

function Card(props : CardProps) : React.JSX.Element {

    const navigate = useNavigate();

    const handleClick = () => {navigate(props.cardData.key);};

    const [isHover, setIsHover] = useState(false); 

    const handleMouseEnter = () => {
        setIsHover(true);
      };
    
      const handleMouseLeave = () => {
        setIsHover(false);
      };

    return <button title='button' className="relative w-full h-auto aspect-[3/2] flex overflow-hidden justify-center items-center text-[#fff]" onClick={handleClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <div className='relative w-full h-full'>
                    <Image key={props.cardData.key} path={props.cardData.imagePath} imageStyle={`${ImageStyles.galleryImage} ${isHover?'scale-[1.1]':''}`}></Image>
                    <Darkness ></Darkness>
                </div>
                <div className='absolute inset-0 flex w-full h-full text-center justify-center'>
                    <Text text={props.cardData.title} textStyle={`${TextStyles.cardupper} ${isHover?'lg:scale-[1.3] md:scale-[1.1] translate-y-[10%]':'translate-y-[0%]'}`}></Text>
                    <Text text={props.cardData.description} textStyle={`${TextStyles.cardbottom} ${isHover?'lg:scale-[1.3] md:scale-[1.1] translate-y-[-10%]':'translate-y-[0%]'}`}></Text>
                </div>
            </button>
}
export default Card;