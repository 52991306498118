import { useEffect, useState } from 'react';

function PhoneDetector() {
  const [isPhone, setIsPhone] = useState(true);

  const handleResize = () => {
    setIsPhone(window.innerWidth < 768);
  };

  useEffect(() => {

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  return isPhone;
}

export default PhoneDetector;