export interface ContactDataInterface {

    text: string
    icon: string
    bigIco: string
}

var contactData : { [name: string]: ContactDataInterface } = {

    location: {
        text:'вул. Івана Мазепи 6А\n82200 Трускавець',
        icon: 'gfx/icons/location.png',
        bigIco : 'gfx/icons/locationBIco.png'
    },
    email: {
        text:'tineo.shop@gmail.com',
        icon: 'gfx/icons/email.png',
        bigIco : 'gfx/icons/emailBIco.png'
    },
    phone: {
        text:'+380 673 249 623',
        icon: 'gfx/icons/phone.png',
        bigIco : 'gfx/icons/phoneBIco.png'
    },
    workdays: {
        text: 'Графік роботи:\nПн.-Пт. 10:00-17:00\nСб. 10:00-15:00\nНд. вихідний',
        icon: 'gfx/icons/clock.png',
        bigIco : 'gfx/icons/clockBIco.png'
    }

}

export {contactData}