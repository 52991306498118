import { CategoryPageProps } from "../../pages/CategoryPage"

const pagesData: {[path: string]: CategoryPageProps} = {

    "/entry-doors" : {

        keyIndex : 'entry-door',

        headerProps : {
            imagePath: "/gfx/contactTitle.jpg",
            titleText: "Вхідні двері"
        }

    },
    "/interior-doors" : {

        keyIndex : 'interior-door',
        headerProps : {
            imagePath: "/gfx/contactTitle.jpg",
            titleText: "Міжкімнатні двері"
        }

    },
    "/glass-partitions" : {

        keyIndex : 'sklyani-dveri',
        headerProps : {
            imagePath: "/gfx/contactTitle.jpg",
            titleText: "Скляні двері"
        }

    },
    "/flooring" : {

        keyIndex : 'pidloga',
        headerProps : {
            imagePath: "/gfx/contactTitle.jpg",
            titleText: "Підлога"
        }

    },
    "/hardware" : {

        keyIndex : 'furnitura',
        headerProps : {
            imagePath: "/gfx/contactTitle.jpg",
            titleText: "Фурнітура"
        }

    },
    
}

export {pagesData}
  