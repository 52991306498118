import React, { useState, useEffect } from 'react';
import { Image, ImageStyles } from '../../../../components/image';
import { MenuList } from '../../../../components/menulist';
import { MenuInterface, links } from '../../../menu/MenuConfig';
import ScrollDetector from '../../../../managers/ScrollDetector';
import { useLocation } from 'react-router-dom';

export interface MainDropDownProps {
  key?: string;
  links: Array<MenuInterface>;
}

function MainDropDown(props: MainDropDownProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const closeMenu = () => {
    setIsOpen(false);
  };

  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolling(ScrollDetector());
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    closeMenu();
  }, [location]);

  useEffect(() => {
    if (isScrolling) {
      closeMenu();
    }
  }, [isScrolling]);

  return (
    <button className='w-12 h-12 justify-items-end mr-2 self-center'>
      <div className="flex space-x-96 items-center" onClick={toggleMenu}>
        <Image path={'/gfx/icons/menu.png'} imageStyle={`${ImageStyles.dropdownIco} ${isOpen ? 'animate-swipe scale-y-[1]  md:transform-none' : ''}`} />
      </div>
      <div className='left-0 top-20 absolute w-full'>
        {isOpen && (
          <ul className={"w-full h-full bg-[#E7E8E7] space-y-6 text-center shadow-md py-4 md:p-8"} onMouseLeave={closeMenu}>
            <MenuList array={links} />
          </ul>
        )}
      </div>
    </button>
  );
}

export default MainDropDown;
