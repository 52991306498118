import { PageHeader } from "../feature/pageheader";
import { PageHeaderProps } from "../feature/pageheader/PageHeader";
import { CategoryImages } from "../feature/categoryimages";
import Axios from "axios";
import { useState, useEffect, useCallback } from "react";
import { mainApi } from "../config";
import { CategoryImagesProps } from "../feature/categoryimages/CategoryImages";
import { useNavigate } from "react-router-dom";

export interface CategoryPageProps {
    keyIndex: string;
    headerProps: PageHeaderProps;
}

function CategoryPage(props: CategoryPageProps) {
    let navigate = useNavigate();

    const [data, setData] = useState<Array<CategoryImagesProps>>([]);
    const [mainImage, setMainImage] = useState<string>('');

    const apiUrl = mainApi + props.keyIndex + '?populate[CategoryBlock][populate]=*&populate[mainImage][populate]=*';

    const fetchData = useCallback(async () => {
        try {
            const response = await Axios.get(apiUrl);
            const container = response.data.data.attributes.CategoryBlock;

            const array: Array<CategoryImagesProps> = [];

            for (const block of container) {
                const array1 = [];

                for (const photos of block.photos.data) {
                    if (photos.attributes.formats.small) {
                        array1.push(photos.attributes.formats.small.url);
                    } else {
                        array1.push(photos.attributes.url);
                    }
                }

                array.push({
                    titleImage: block.PartnerImage.data.attributes.url,
                    pathImages: array1,
                });
            }

            setMainImage(response.data.data.attributes.mainImage.data.attributes.url);
            setData(array);
        } catch (error) {
            navigate('/serverconnection');
        }
    }, [apiUrl, navigate]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <div className="w-full h-min">
            <PageHeader imagePath={mainImage} titleText={props.headerProps.titleText} />
            {getContentOnPage(data)}
        </div>
    );
}

function getContentOnPage(data: Array<CategoryImagesProps>) {
    const array = data.map((block, index) => (
        <CategoryImages key={index.toString()} titleImage={block.titleImage} pathImages={block.pathImages} />
    ));
    return array;
}

export { CategoryPage };
