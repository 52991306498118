import React, { useEffect, useState } from "react";
import Card from "../../components/card/Card";
import { GalleryInterface, galleryData } from './galleryData';
import Axios from "axios";
import { mainApi } from "../../config";
import { useNavigate } from "react-router-dom";

function Gallery() {

    let navigate = useNavigate();

    const [data, setData] = useState<Array<GalleryInterface>>(galleryData);
    
    const apiUrl = mainApi  + 'domashnya-storinka?populate[CategoryBoxes][populate]=*';
    
    const fetchData = async () => {
        try {
          const response = await Axios.get(apiUrl);
    
          const container = response.data.data.attributes.CategoryBoxes

            const array: Array<GalleryInterface> = []
            
            for (const block of container){
                array.push({
                    key: block.key,
                    imagePath: block.image.data.attributes.url,
                    title: block.title,
                    description: block.description
                })
            }

          setData(array);

        } catch (error) {
          navigate('/serverconnection');
        }
      };
    
      useEffect(() => {
        fetchData();
      });

    const array : Array<React.JSX.Element> = []
    for (const cardData of data){

        array.push(<Card key={data.indexOf(cardData)} cardData={cardData}></Card>)

    }

    return <div className="grid my-12 gap-y-3 gap-y-1 md:grid-cols-2 md:gap-y-6  lg:grid-cols-3 lg:gap-y-12">{array}</div>
}

export default Gallery;