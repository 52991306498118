import { Darkness } from "../../components/darkness";
import { Image,ImageStyles } from "../../components/image";
import { Text,TextStyles } from "../../components/text";
export interface PageHeaderProps {

    key? : string
    imagePath : string,
    titleText : string

}

function PageHeader(props : PageHeaderProps){




    return  (
    <div className="relative w-full h-max">
        <div className="relative">
            <Image key="contactTitle" path={props.imagePath} imageStyle={ImageStyles.headerPage}></Image>
            <Darkness ></Darkness>
            <div className="absolute flex top-0 left-0 w-full h-full items-end">
                <Text text={props.titleText} textStyle={TextStyles.contactTitlePage}></Text>
            </div>
        
        </div>
    </div>
    );

}

export {PageHeader}