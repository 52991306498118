import { Text } from "../components/text";

function ReqeustError() {
    return (
      <section>

        <div className="w-full h-full">

            <Text text={"Ведуться технічні роботи"} textStyle="text-[#484848] text-3xl text-center mt-24"/>
            <Text text={"Немає з'єднання з сервером"} textStyle="text-[#FF0000] text-2xl text-center my-8"/>
            <Text text={"Спробуйте увійти пiзніше"} textStyle="text-[#484848] text-2xl text-center mb-24"/>


        </div>

      </section>
      
    );
  }
  
  export default ReqeustError;