import { PageHeader } from "../feature/pageheader";
import { ImagePopup } from "../components/imagePopup";
import Axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ImageStyles } from "../components/image";
import { mainApi } from "../config";

function Portfolio() {
  let navigate = useNavigate();

  const [data, setData] = useState<Array<string>>([]);
  const [mainImage, setmainImage] = useState<string>('');

  const apiUrl = mainApi +'galereya?populate=*';
  
  const fetchData = async () => {

      try {
        const response = await Axios.get(apiUrl);
  
        const container = response.data.data.attributes

        const array: Array<string> = []
          
        for (const block of container.images.data){
          if(block.attributes.formats.small){
            array.push(block.attributes.formats.small.url)
          } else {
            array.push(block.attributes.url)
          }
        }
          
        setmainImage(response.data.data.attributes.mainImage.data.attributes.url)
        setData(array);

      } catch (error) {
        navigate('/serverconnection');
      }
    };
  
    useEffect(() => {
      fetchData();
    });

  const images = []
  const listImage = []

  for (const imageData of data){
    listImage.push({key: imageData, path: imageData, imageStyle: ImageStyles.categoryIcoCotained})
  }

  for (let i = 0; i < data.length;i++){

      images.push(
          <ImagePopup key={i} images={listImage} initialIndex={i} specialStyle={"mx-0 my-2"} borderStyle="relative w-full h-full"></ImagePopup>
      )
      
  }

  return (
    <section>
      <div className="w-full h-min">
        <PageHeader imagePath={mainImage} titleText={'Реалізовані проєкти'} />
        <div className="flex w-full h-min justify-center items-start">
          <div className="
            w-full
            px-12
            grid-rows-1 
            md:grid-rows-none 
            md:columns-4
            xl:container 
            xl:columns-xs
            xl:mx-auto
            xl:gap-12">
            {images}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Portfolio;
