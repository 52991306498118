import { PageHeader } from "../feature/pageheader";
import { Image, ImageStyles } from "../components/image";
import { ContactDataInterface, contactData } from "../components/contactblock/ContactData";
import { Text, TextStyles } from "../components/text";
import { ImagePopup } from "../components/imagePopup";
import Axios from "axios";
import { useState, useEffect } from "react";
import { mainApi} from "../config";
import { useNavigate } from "react-router-dom";

function Contact() {
  let navigate = useNavigate();
  const [data, setData] = useState<{[name: string]: ContactDataInterface}>(contactData);
  const [images, setImages] = useState<Array<string>>(['gfx/ContactPhoto1.png','gfx/ContactPhoto.png']);



  const apiUrl = mainApi  + 'kontaktni-danni?populate=*';

  const fetchData = async () => {
    try {
      const response = await Axios.get(apiUrl);

      const container = response.data.data.attributes

      contactData['location'].text = container.location
      contactData['email'].text = container.email
      contactData['phone'].text = container.phone
      contactData['workdays'].text = container.workdays

      const array = [container.imageInside.data.attributes.url,container.imageOutside.data.attributes.url]
      setImages(array)

      setData(contactData);

    } catch (error) {
      navigate('/serverconnection');
    }
  };

  useEffect(() => {
    fetchData();
  });

  return (

    <div className="w-full h-min">
      <meta name="description" content={data['location'].text  + "|" + data['email'].text + "|" + data['phone'].text + "|" + contactData['workdays'].text} />
      <PageHeader imagePath={"/gfx/contactTitle.jpg"} titleText={"Контактні дані"} />
    
      <div className="flex justify-center items-center w-full h-max bg-[#F1F1F1]">

        <div className="grid grid-cols-1 gap-3 w-max h-max p-8 md:grid-cols-2 my-12">
          {getData(data)}

        </div>
      </div>

      <div className="flex w-full h-max bg-[#CCCCCC] justify-center items-center">

        <div className="grid grid-cols-1 gap-12 w-max h-max md:grid-cols-2 my-12">
          
        <ImagePopup specialStyle='' images={[{ path: images[0], imageStyle: ImageStyles.contactPageBig }, { path: images[1], imageStyle: ImageStyles.contactPageBig }]} initialIndex={0} borderStyle="relative w-full h-full"></ImagePopup>
        <ImagePopup specialStyle='' images={[{ path: images[1], imageStyle: ImageStyles.contactPageBig }, { path: images[0], imageStyle: ImageStyles.contactPageBig }]} initialIndex={0} borderStyle="relative w-full h-full"></ImagePopup>

        </div>

      </div>

    </div>
    
  );
}

function getData(data: {[name: string]: ContactDataInterface}){

  return Object.keys(data).map((key) => (
    <div key={key} className="flex w-max h-full items-center">
      <div className="h-min w-24">
        <Image
          path={contactData[key].bigIco}
          imageStyle={ImageStyles.contactPageIco}
        ></Image>
      </div>

      <div className="w-full h-min">

        {getText(data[key].text)}

      </div>

    </div>
  ));
}

function getText(text : string){

  return <Text text={text} textStyle={TextStyles.contactTextPage}/>

}

export default Contact;