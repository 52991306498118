import { Text,TextStyles } from "../../components/text";
import { Image, ImageStyles } from "../../components/image";
function ChooseAs(){

    return (
    <div className='w-full h-max bg-[#525252] mb-12 pb-12'>
        <div className="flex justify-center items-center w-full h-min mb-8 bg-[#484848]">
            <Text text={'Чому обирають нас'} textStyle={TextStyles.serviceTitle} ></Text>
        </div>
        
        <div className="grid grid-cols-2 gap-y-8 md:gap-y-0 md:grid-cols-4 w-full h-min self-end mt-12">

            <div className="w-full h-full">
                <div className="flex w-full justify-center items-start pb-4">
                    <Image path={"gfx/chooseUs/idea_ico.png"} imageStyle={ImageStyles.chooseUsIco}></Image>
                </div>
                <Text text={'Індивідуальні рішення'} textStyle={TextStyles.chooseUsText} ></Text>
            </div>

            <div className="w-full h-full">
                <div className="flex w-full justify-center items-start pb-4">
                    <Image path={"gfx/chooseUs/dopomga_ico.png"} imageStyle={ImageStyles.chooseUsIco}></Image>
                </div>
                <Text text={'Технічна допомога'} textStyle={TextStyles.chooseUsText} ></Text>
            </div>
            <div className="w-full h-full">
                <div className="flex w-full justify-center items-start pb-4">
                    <Image path={"gfx/chooseUs/montazh_ico.png"} imageStyle={ImageStyles.chooseUsIco}></Image>
                </div>
                <Text text={'Кваліфікований монтаж'} textStyle={TextStyles.chooseUsText} ></Text>
            </div>

            <div className="w-full h-full">
                <div className="flex w-full justify-center items-start pb-4">
                    <Image path={"gfx/chooseUs/garantee_ico.png"} imageStyle={ImageStyles.chooseUsIco}></Image>
                </div>
                <Text text={"Гарантійні зобов'язання"} textStyle={TextStyles.chooseUsText} ></Text>
            </div>

        </div>

    </div>
    );

}

export {ChooseAs}