const partners : Array<string> = [

    'gfx/partnersIco/agt-logo.png',
    'gfx/partnersIco/art-door-logo.png',
    'gfx/partnersIco/frescio-logo.png',
    'gfx/partnersIco/grand-logo.png',
    'gfx/partnersIco/hermat-logo.png',
    'gfx/partnersIco/liberta-logo.png',
    'gfx/partnersIco/papa-carlo-logo.png',
    'gfx/partnersIco/porta-nova-logo.png',
    'gfx/partnersIco/sliding-doors-logo.png',
    'gfx/partnersIco/straj-logo.png',
    'gfx/partnersIco/twin-logo.png'

]

export {partners}