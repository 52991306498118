import { useState, useEffect } from 'react';
import Axios from 'axios';
import { Text, TextStyles } from '../../components/text';
import { Image, ImageStyles } from '../../components/image';
import { Darkness } from '../../components/darkness';
import { mainApi } from '../../config';
import { useNavigate } from 'react-router-dom';
import PhoneDetector from '../../managers/PhoneDetector';

export interface IntroData {

  text : string
  image : string
}

let isPhone : boolean

function Intro() {

  

  let navigate = useNavigate();
  isPhone = PhoneDetector();

  const [data, setData] = useState<IntroData>(
    {

      image : "",
      text : ""

    }
    );

  const apiUrl = mainApi  + 'domashnya-storinka?populate[Intro][populate]=*';

  const fetchData = async () => {
    try {
      const response = await Axios.get(apiUrl);

      const container = response.data.data.attributes.Intro

      setData(
        {
          text : container.text,
          image : container.image.data.attributes.url
        }
        );

    } catch (error) {
      navigate('/serverconnection');
    }
  };

  useEffect(() => {
    fetchData();
  });

  const openInstagram = () => {
    window.open('https://instagram.com/studio_doors_tineo?igshid=YTQwZjQ0NmI0OA==')
  };
  const openFacebook = () => {
    window.open('https://www.facebook.com/profile.php?id=100042066980184')
  };
  

  return (
    <div className="relative h-96 md:h-max">
      <Image path={data.image} imageStyle={ImageStyles.intro}></Image>
      <Darkness></Darkness>
      <div className='absolute w-full h-full inset-0'>
        <div className='relative w-full h-full flex justify-center items-center text-[#fff]'>
          <Text text={data.text} textStyle={TextStyles.intro}></Text>

          {!isPhone && (
            <div className='absolute w-full h-min pr-8 md:pr-2 lg-:pr-8'>
              <div className='w-full h-full flex justify-end items-center'>
                <div>
                <button title='instagram' onClick={openInstagram} className='size-full flex justify-center items-center mb-4'>
                    <Image path={'gfx/icons/InstagramBig.png'} imageStyle={ImageStyles.socialIco}></Image>
                  </button>
                  <button title='instagram' onClick={openFacebook} className='size-full flex justify-center items-center'>
                    <Image path={'gfx/icons/FacebookBig.png'} imageStyle={ImageStyles.socialIco} ></Image>
                  </button>
                </div>
              </div>
            </div>
          )}

        </div>
      </div>
    </div>
  );
}

export default Intro;


// {!isPhone && (
//   <div className='absolute w-full h-min pr-8'>
//     <div className='w-full h-full flex justify-end items-center'>
//         <button title='instagram' onClick={openInstagram} className='w-16 h-16 flex justify-center items-center'>
//           <Image path={'gfx/icons/InstagramBig.png'} imageStyle={ImageStyles.socialIco}></Image>
//         </button>
//     </div>

//     <div className='w-full h-full flex justify-end items-center mt-6'>
//         <button title='instagram' onClick={openFacebook} className='w-16 h-16 flex justify-center items-center'>
//           <Image path={'gfx/icons/FacebookBig.png'} imageStyle={ImageStyles.socialIco} ></Image>
//         </button>
//     </div>
//   </div>
// )}
