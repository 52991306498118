import { partners } from "./PartnersList"
import { Image, ImageStyles } from "../../components/image"
import { useState, useEffect } from "react";
import Axios from "axios";
import { mainApi} from "../../config";
import { useNavigate } from "react-router-dom";

function Partners(){

    let navigate = useNavigate();
    const [data, setData] = useState<Array<string>>(partners);
    
    const apiUrl = mainApi  + 'domashnya-storinka?populate[PartnersPhoto][populate]=*';
    
    const fetchData = async () => {
        try {
          const response = await Axios.get(apiUrl);
    
          const container = response.data.data.attributes.PartnersPhoto

            const array: Array<string> = []
            
            for (const partner of container.data){
                array.push(partner.attributes.url)
            }

          setData(array);
        } catch (error) {
          navigate('/serverconnection');
        }
      };
    
      useEffect(() => {
        fetchData();
      });

    const partnersArray : Array<JSX.Element> = []

    for (const ico of data){

        partnersArray.push(

            <Image key={ico} path={ico} imageStyle={ImageStyles.partnerIco}></Image>

        )

    }

    return <div className="flex flex-wrap justify-center gap-x-4 gap-y-8 w-full h-max justify-center items-center py-12 mf:gap-x-16"> {partnersArray} </div>

}


export default Partners