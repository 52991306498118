import { ButtonLink,ButtonLinkStyles } from "../../components/buttonlink";
import { Darkness } from "../../components/darkness"
import { Image,ImageStyles } from "../../components/image"
import { Text,TextStyles } from "../../components/text"

function ContractIntro(){
    return <div className="relative overflow-hidden w-full h-96">
        <Image path={"/gfx/mainBg.png"} imageStyle={ImageStyles.contactIntro}></Image>
        <Darkness></Darkness>
        <div  className="absolute justify-center text-center z-[1] text-[#fff] top-0 inset-x-0">
            <Text text='хочете дізнатись більше інформації?' textStyle={TextStyles.contactIntro}></Text>
            
            <ButtonLink buttonStyle={ButtonLinkStyles.contactButton} text={"Зв'яжіться з нами"} textStyle={TextStyles.contactIntroButton}link={"/contact"}></ButtonLink>

        </div>
    </div>

}

export default ContractIntro