
export interface TextProps {

    key? : string,
    text : string,
    textStyle : string
    textType? : string

}

function Text(props : TextProps) {
    if(props.textType){return <h1 className={`${props.textStyle} whitespace-pre-line`}>{props.text}</h1>}
    return <p className={`${props.textStyle} whitespace-pre-line`}>{props.text}</p>
}
export default Text;