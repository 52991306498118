import React from 'react';
import './App.css';
import Header from './feature/header/Header';
import NavigationPages from './feature/navigator/NavigationPages';
import Footer from './feature/footer/Footer';
import ScrollToTop from './managers/ScrollToTop'

function App() {

  return (
      <div>
        <ScrollToTop />
        <div className="App">
          <Header />
          <NavigationPages />
          <Footer />
        </div>
      </div>
  );
}

export default App;