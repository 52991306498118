import { useState} from "react";
import { ImageProps } from "../image/Image";
import Image from "../image/Image";
import { ImageStyles } from "../image/Styles";

export interface ImagePopupProps {
  images: ImageProps[];
  initialIndex: number;
  specialStyle: string;
  borderStyle: string;
}


function ImagePopup(props: ImagePopupProps) {
  const [currentIndex, setCurrentIndex] = useState(props.initialIndex);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (index: number) => {
    setCurrentIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setCurrentIndex(props.initialIndex);
    setIsModalOpen(false);
  };

  const goToPrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    } else {
      setCurrentIndex(props.images.length - 1);
    }
  };

  const goToNext = () => {
    if (currentIndex < props.images.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      setCurrentIndex(0);
    }
  };

  return (
    <div className={`h-full ${props.specialStyle !== '' ? 'w-full' :'w-full'}`}>
      <button
        title="open"
        onClick={() => openModal(currentIndex)}
        className={`w-full pointer-events-none md:pointer-events-auto ${props.specialStyle !== '' ? props.specialStyle + '' :'w-full'}`}
      >

        <div className={props.borderStyle}>
            <Image
            key={props.images[props.initialIndex].key}
            path={props.images[props.initialIndex].path}
            imageStyle={props.images[props.initialIndex].imageStyle}
            loading="lazy"
            ></Image>

            <div className="transition-all duration-500 absolute flex inset-0 bg-black opacity-0 hover:opacity-50 justify-center items-center">

                <Image
                key={'/gfx/closeIco.png'}
                path={'/gfx/closeIco.png'}
                imageStyle={ImageStyles.loopImage}
                loading="lazy"
                ></Image>

            </div>
        </div>

      </button>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 overflow-hidden">
          <div className="w-full h-full bg-black bg-opacity-90"></div>
          <div className="absolute top-0 w-full h-full">
            <div className="relative flex w-full h-full justify-center items-center">
              <div className="relative flex w-max h-max p-1 justify-center items-center">
                <Image
                  key={props.images[currentIndex].key}
                  path={props.images[currentIndex].path}
                  imageStyle={`${ImageStyles.popupImage} ${
                    isModalOpen ? "animate-scaleup" : ""
                  }`}
                ></Image>
                <div className="absolute flex w-full h-full items-start justify-end ">
                  <button
                    title="Close"
                    className="relative flex w-4 h-4 mr-4 mt-4 text-white"
                    onClick={closeModal}
                  >
                    <Image
                      key={'/gfx/closeIco.png'}
                      path="gfx/closeIco.png"
                      imageStyle={ImageStyles.closeIco}
                    ></Image>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <button
                  title="Previous"
                  className="absolute flex justify-center items-center left-0 top-0 h-full w-12 scale-x-[-1] bg-transparent hover:bg-[#bababa1A]"
                  onClick={goToPrevious}
                >
                  <Image
                    key={'/gfx/aroowImage.png'}
                    path={'/gfx/aroowImage.png'}
                    imageStyle={ImageStyles.arrowImage}
                    ></Image>
                </button>
                <button
                  title="Next"
                  className="absolute flex justify-center items-center right-0 top-0 h-full w-12 bg-transparent hover:bg-[#bababa1A]"
                  onClick={goToNext}
                >
                  <Image
                    key={'/gfx/aroowImage.png'}
                    path={'/gfx/aroowImage.png'}
                    imageStyle={ImageStyles.arrowImage}
                    ></Image>
                </button>

        </div>
      )}
    </div>
  );
}

export default ImagePopup;
