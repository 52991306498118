import React from 'react';
import { imagePath } from '../../config';

export interface ImageProps {
  key?: string;
  path: string;
  imageStyle?: string;
  loading?:  "eager" | "lazy"
}

function Image(props: ImageProps) {
  return (
    <img
      src={imagePath + props.path}
      alt={props.path}
      className={props.imageStyle}
      loading={props.loading}
    />
  );
}

export default Image;
