import React from 'react';
import { Image,ImageStyles } from '../../components/image';
import { Menu } from '../menu';
import useStickyScroll from '../../managers/useStickyScroll';
import { MainDropDown } from './components/maindropdown';

import { links } from '../menu/MenuConfig';
import PhoneDetector from '../../managers/PhoneDetector';
import { NavigateFunction, useNavigate } from 'react-router-dom';

let isSticky : boolean
let isPhone : boolean
let navitage : NavigateFunction

function Header(): React.JSX.Element {

  isSticky = useStickyScroll();
  isPhone = PhoneDetector();
  navitage = useNavigate()

  return (
    <header className={`flex flex-col items-center relative bg-gray-100 h-12 z-10 md:h-36 xl:h-64 ${isSticky ? 'justify-start' : 'justify-end'}`}>
      {getHeader()}
    </header>
    
  );
}

function getHeader(){
  

    return ( <div className='fixed w-full h-20 top-0 md:relative md:top-auto md:h-min md:justify-center md:items-center'>
        {!isPhone && (
          <div className='w-full justify-center items-center'>
            <div className="flex-grow"></div>
              <div className='flex items-center justify-center mt-auto translate-y-8 z-10'>
                <Image path={'/gfx/logo.png'} imageStyle={`${ImageStyles.header} ${isSticky ? ' invisible' : ''}`}></Image> 
              </div>
              <div className='flex justify-center items-center shadow-lg'>
                <div className='absolute left-0 bottom-0 bg-[#484848] w-full h-16 z-[-1]'></div>
                <Menu />
              </div>
          </div>
        )}

        {isPhone && (
          <div className='flex w-full h-full justify-end items-center bg-white'>
            <div className='absolute flex w-full h-full justify-end'>
                <button title='facebook' onClick={() => window.open('https://www.facebook.com/profile.php?id=100042066980184')} className='w-auto h-8 flex self-center justify-center items-center mr-4'>
                  <Image path={'gfx/icons/Facebook.png'} imageStyle={ImageStyles.socialSmallIco} ></Image>
                </button>
                <button title='instagram' onClick={() => window.open('https://instagram.com/studio_doors_tineo?igshid=YTQwZjQ0NmI0OA==')} className='w-auto h-8 flex self-center justify-center items-center mr-2'>
                  <Image path={'gfx/icons/Instagram.png'} imageStyle={ImageStyles.socialSmallIco} ></Image>
                </button>
                <MainDropDown key={'maindropdown'} links={links}></MainDropDown>
            </div>

            <div className='absolute w-full pointer-events-none'>
              <button title='home' onClick={() => {navitage('/')}} className='ml-4 flex justify-center items-center pointer-events-auto'>
                <Image path={'/gfx/logo.png'} imageStyle={ImageStyles.headerSmall}></Image>
              </button>
            </div>

          </div>
        )}

      </div>
    );
}

export default Header;