import React from 'react';
import { TextStyles } from "../text";
import { Dropdown } from "./components/dropdownmenu";
import MenuButton from './components/menubutton/MenuButton';
import { MENUTYPE, MenuInterface } from '../../feature/menu/MenuConfig';

export interface MenuListProps {

  array : Array<MenuInterface>

}

function MenuList(props : MenuListProps){

    var menuButtonsList : Array<React.JSX.Element> = [];
    const array = props.array

    for (const link of array){
      if (link.type === MENUTYPE.BUTTON && link.link) {
        menuButtonsList.push(<MenuButton key={link.link} text={link.text} path= {link.link} style= {TextStyles.mainbutton}></MenuButton>);
      } if (link.type === MENUTYPE.DROPDOWN && link.links){
        menuButtonsList.push(<Dropdown key={link.text} maintext={link.text} dropdown={link.links}></Dropdown>)
      } if (link.type === MENUTYPE.INDROPBUTTON && link.link){
        menuButtonsList.push(<MenuButton key={link.link} text={link.text} path= {link.link} style= {TextStyles.dropDownText}></MenuButton>);
      }
    }


    return menuButtonsList;

}   

export default MenuList