
export interface GalleryInterface {

    key: string,
    imagePath : string,
    title : string,
    description : string
}

var galleryData: Array<GalleryInterface> = [
    {
        key : "entry-doors",
        imagePath : 'gfx/galleryPhotos/entry-doors.jpg',
        title : "Вхідні двері",
        description : "Замовляйте у нас вхідні двері високої якості та надійності."
    },
    {
        key : "interior-doors",
        imagePath : 'gfx/galleryPhotos/interior-doors.png',
        title : "Міжкімнатні двері",
        description : "Великий вибір міжкімнатних дверей різних стилів та дизайну."
    },
    {
        key : "glass-partitions",
        imagePath : 'gfx/galleryPhotos/glass-partitions.jpg',
        title : "Скляні перегородки",
        description : "Додайте просторості та елегантності своєму інтер'єру зі скляними перегородками."
    },
    {
        key : "flooring",
        imagePath : 'gfx/galleryPhotos/flooring.png',
        title : "Підлога",
        description : "Замовляйте у нас підлогу високої якості та надійності."
    },
    {
        key : "hardware",
        imagePath : 'gfx/galleryPhotos/hardware.png',
        title : "Фурнітура",
        description : "Комплектуйте свої двері та перегородки якісною фурнітурою."
    },
    {
        key : "about",
        imagePath : 'gfx/galleryPhotos/about.png',
        title : "Галерея",
        description : "Ознайомтесь з нашими успішними проектами та вражаючими роботами."
    }
]

export {galleryData}