import { Intro } from '../feature/intro';
import { Gallery } from '../feature/gallery';
import { ContractIntro } from '../feature/contactintro';
import { Partners } from '../feature/partners';
import { ChooseAs } from '../feature/chooseas';

function Home() {

  return (
    <section>
      <meta name="description" content="Вхідні двері|Міжкімнатні двері|Скляні перегородки|Підлога|Фурнітура|Галерея" />
      <Intro />
      <Gallery />
      <ChooseAs />
      <ContractIntro />
      <Partners />
    </section>
    
  );
}

export default Home;