import { Text } from "../text";
import { useNavigate } from "react-router-dom";


export interface ButtonLinkProps {

    buttonStyle: string
    link : string
    text? : string
    textStyle?: string


}

function ButtonLink(props : ButtonLinkProps) {

    const navigate = useNavigate();

    const handleClick = () => {navigate(props.link);};

    return <button className={props.buttonStyle} onClick={handleClick}>{getText(props.text,props.textStyle)}</button>

}

function getText(text? : string,textStyle? : string){

    if (text && textStyle)
    
    return <Text text={text} textStyle={textStyle}></Text>
}

export {ButtonLink}