import React from "react";
import { PageType, pages } from "./Pages"
import { Route, Routes } from 'react-router-dom';
import Home from "../../pages/Home";
import Portfolio from "../../pages/Portfolio";
import Contact from "../../pages/Contact";
import { pagesData } from "./PagesData";
import {CategoryPage,CategoryPageProps } from "../../pages/CategoryPage";
import ReqeustError from "../../pages/ReqeustError";
import { Navigate } from "react-router-dom";

const pageList: {[path: string]: JSX.Element} = {

      "/": <Home />,
      "/about": <Portfolio />,
      "/contact": <Contact />,

}

function NavigationPages() : JSX.Element {
    return (
      <main>
        <Routes>
          {createMenu()}
          <Route path='/serverconnection' element={<ReqeustError />}/>
          <Route path='*' element={<Navigate to="/" />}/>
        </Routes>
      </main>
    );
  }
  
  function createMenu() {
    var menuButtonsList: Array<React.ReactElement> = [];
    
    for (const page of pages){
      if (page.pageType === PageType.NORMAL) {
        menuButtonsList.push(getMenuButton(page.path, pageList[page.path]));
      } else {
        menuButtonsList.push(getMenuButton(page.path,getTemplatePage(pagesData[page.path])))
      }
    }

    return menuButtonsList;
  }
  function getMenuButton(path : string, component : JSX.Element) {
    return <Route key={path} path={path} element={component} />;
  }

  function getTemplatePage(data : CategoryPageProps): JSX.Element{

    return (  

      <CategoryPage key={data.keyIndex} keyIndex={data.keyIndex} headerProps={data.headerProps} />

    );

  }

export default NavigationPages