import React from 'react';
import { Link } from 'react-router-dom';
import { Text } from '../../../text';

export interface MenuButtonInterface {

  key?: string
  path: string,
  text: string,
  style: string

}

function MenuButton(props : MenuButtonInterface) : React.JSX.Element{

    return (
        <li>
          <Link to={props.path}>
            <Text textType="H" text={props.text} textStyle={props.style}></Text>
          </Link>
        </li>
      );

}

export default MenuButton